.montserrat-text {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}

.main{
    padding-left: 20px;
    padding-right: 20px;
}

@media only screen and (max-width: 600px){
    .main{
        padding-left: 5px;
        padding-right: 5px;
    }
    .MuiChartsLegend-series text{
        font-size: .7rem !important;
    }
}

.results-card-header{
    background-color: rgba(153, 204, 255, .5);
}

.comparison-table-header{
    background-color: rgba(153, 204, 255, .5) !important;
}

.pad5{
    padding-right: 5px;
    padding-left: 5px;
    text-align: center;
}

.center-text{
    text-align: center;
}

.results-line{
    margin-top:16px;
    border-bottom: 1px solid rgba(153, 204, 255, .5);
    width: 100%;
    margin-left: 16px;
}

.results-line-btm-margin{
    margin-top:16px;
    border-bottom: 1px solid rgba(153, 204, 255, .5);
    width: 100%;
    margin-bottom: 16px;
}

.org-type-selection{
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 20px;
}

.results-card{
    margin-top: 25px;
}

.cpc-results-container{
    margin-top: 15px;
}

.cpc-child-grid{
    width: 100%;
    margin-left: 0px;
    justify-content: center;
    display: flex;
}

.cpc-container{
      width: 100%;
      margin-left: 0px;
      justify-content: center;
      display: flex;
  }

.header-links {
    font-family: "Montserrat", sans-serif !important;
    font-optical-sizing: auto !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-size: 13px !important;
    text-decoration: none !important;
    color: rgb(24, 24, 24) !important;
    cursor: pointer;
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
    text-wrap: nowrap;
}

.header {
    margin-top: 15px;
    margin-left: 20px;
    margin-right: 20px;
}

.pdf-header {
    margin-bottom: 40px;
}

.pdf-data-grid{
    font-weight: 300;
    padding-bottom: 20px;
}

.welcome{
    font-weight: 300;
}

.pdf-margin{
    margin-left: 0 !important;
}

.pdf-data-section{
    padding-top: 20px !important;
}

.nav-grid{
    display: flex;
    align-items: flex-end;
}

.nav-menu-grid{
    display: flex;
    align-items: center;
}

.title-container {
    margin-top: 25px;
    margin-bottom: 15px;
    display: flex;
}

.footer-container {
    margin-top: 25px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    color: rgb(153,153,207);
}

.cpc-title{
    color: rgb(153,153,207);
    font-weight: 600 !important;
}

.title-box {
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
}

.footer-box {
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
}

.footer-disclaimer-box {
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    max-width: 80vw;
}

.header-links:hover {
    color: rgb(153, 204, 255) !important;
}

.logo {
    width: 237px;
}

.header-container {
    justify-content: flex-end;
    flex: 1;
    margin-right: 15px !important;
    align-items: flex-end;
}

.results-grid-left, .results-grid-left{
    display: flex;
}

.results-grid-left{
flex:1;
    display: flex;
    justify-content: flex-start;
}

.results-grid-right{

    display: flex;
    justify-content: flex-end;
}

.section-intro{
    margin-bottom: 25px;
    font-weight: 300;
    font-size: .9rem;
}

.user-enter-salaries-help{
    font-weight: 300 !important;
    font-size: .9rem !important;
}

.copyright{
    font-weight: 300;
}

